// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    clearfix();
  }

  li {
    float: left;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Breadcrumbs

.breadcrumbs {
  li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }

  span {
    color: $black;
  }

  a {
    text-decoration: none;
    color: $black;

    &:hover {
      color: $black;
    }
  }
}


// Pager

.pager {
  text-align: center;

  li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
  }

  a {
    display: block;
    width: 25px;
    line-height: 25px;
    text-decoration: none;
    font-size: 1.4rem;
    color: $gray;
  }

  li.active a {
    color: $black;
  }

  li:not(.active) a {
    &:hover {
      color: $black;
    }
  }

  .previous a,
  .next a {
    width: width('i/arrow-right.svg'); height: height('i/arrow-right.svg');
    position: relative;
    opacity: .5;
    transition: opacity .25s ease;

    @media $small-mobile {
      width: 40px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%; height: 30px;
      position: absolute;
      left: 0; top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      opacity: 1;
    }
  }

  @media $not-small-mobile {
    .previous {
      margin-right: 30px;
    }

    .next {
      margin-left: 30px;
    }
  }

  .previous a {
    background: url(/i/arrow-left.svg) no-repeat 0 50% !important;
  }

  .next a {
    background: url(/i/arrow-right.svg) no-repeat 100% 50% !important;
  }
}
