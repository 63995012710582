.error-page {
  height: 100%;
  background: url(/i/404-bg.jpg) no-repeat 50% / cover;

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.error-page__content {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 552px; min-height: 577px;
  padding-left: 150px;
  max-width: 100%;
  background: url(/i/brand-circle-white.svg) no-repeat 0 50% / contain;
  margin-left: -70px;

  @media $notebook-and-less {
    padding-left: 160px;
  }

  @media $handheld {
    width: 460px; min-height: 470px;
    margin-left: -40px;
    padding-left: 130px;
  }

  @media $small-handheld {
    border: none;
  }

  @media $small-handheld {
    width: auto;
    border: none;
    padding-left: 0;
    padding-right: 0;
    min-height: auto;
    background: none;
    margin-left: 0;
  }
}

.error-page__caption {
  @extend .h2;
  margin-top: 1em;

  @media $mobile {
    font-size: 4.2rem;
    margin-top: 2em;
  }
}

.error-page__text {
  @extend .big-text;
  margin-bottom: 1.5em;
  white-space: nowrap;

  @media $small-mobile {
    font-size: 1.8rem;
  }
}

.error-page__button {
  @extend .btn;
  display: block;
  width: 100px;
  margin-left: auto;
  margin-right: 15%;
  font-size: 1.5rem;
  padding-left: 132px;
  background: url(/i/arrow-left.svg) no-repeat 0 50%;
}
