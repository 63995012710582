.ui-autocomplete {
  z-index: 1010;
  max-height: 431px;
  overflow: auto;
  background: $white;
  list-style: none;
  word-wrap: break-word;
  border: 2px solid $blue;
  border-top-width: 1px;
  box-sizing: border-box;

  .ps-scrollbar-y-rail {
    z-index: 100;
  }

  @media $landscape-mobile {
    left: auto !important;
    right: 20px;
    width: 300px !important;
  }
}

.ui-menu {
  .ui-menu-item {
    padding: 0;

    > a {
      display: block;
      color: $black;
    }

    &:hover {
      z-index: 10;
    }

    + .ui-menu-item {
      margin-top: -1px;
    }
  }
}

.autocomplete-item {
  display: flex;
  align-items: center;
  padding: 5px;
  background: white;

  &:hover {
    background: $light-gray;
    z-index: 10;
  }

  span {
    display: inline-block;
    max-width: 100%;
  }
}

.autocomplete-item--pic {
  width: 30px;
  height: 30px;
  text-align: center;
  flex-shrink: 0;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.autocomplete-item--content {
  display: block;
  font-size: 12px;
  line-height: 120%;
  margin-left: 10px;

  .autocomplete-pic + & {
    margin-left: 85px;
  }
}

.autocomplete-item--price {
  padding-top: 10px;
}


// Selectric

.select-container {
  display: inline-block;
}

.selectric {
  border: 1px solid #BDBDBD;
  background: $white;
  color: #000;
  height: 41px;
  line-height: @height - 3px;
  text-align: left;

  .label {
    font-size: 2rem;
    margin-left: 25px;
    margin-right: 47px;
  }

  .button {
    width: 47px; height: 100%;

    &:after {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      width: 8px; height: 8px;
      border-left: 3px solid #000;
      border-bottom: 3px solid #000;
      border-radius: 1px;
      position: absolute;
      left: 50%; top: 50%;
      transform: rotate(-45deg) translate(50%, -50%);
      margin-left: -3px;
    }
  }
}

.selectric-items {
  text-align: left;

  li {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1.6rem;
  }

  .disabled {
    display: none;
  }
}


// alertify

.alertify {
  border: 2px solid $black;
  width: 250px;
  margin-left: 0;
  top: 50%; left: 50% !important;
  transform: translate(-50%, -50%);
  background: $white;
}

.alertify-message {
  margin-bottom: 15px;
}

.alertify-button {
  display: inline-block;
  margin-left: 0 !important;
  line-height: 30px !important;
  width: 40px;
}

.alertify-button-ok {
  background: $black;
  color: $white;
  box-shadow: 0 0 0 2px $black;

  &:hover {
    background: $white;
    color: $black;
  }
}

.alertify-button-cancel {
  background: $white;
  box-shadow: 0 0 0 2px $black;
  margin-left: 10px !important;

  &:hover {
    background: $black;
    color: $white;
  }
}
