// Base

.popup {
  display: none;
  position: absolute;
  background: $body-bg;
  z-index: 9999;
  padding: 33px 42px 43px 43px;

  @media $small-mobile {
    padding: 65px 20px 30px;
  }
}

.popup__close {
  @extend .btn;
  padding-left: 130px;
  cursor: pointer;
  color: $black;
  font-family: $caption-font;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  background: url(/i/arrow-left.svg) no-repeat 0 50%;
  margin-bottom: 1em;

  @media $small-mobile {
    font-size: 1.8rem;
  }
}


.product-popup {
  box-sizing: border-box;
  color: $black;
  font-size: 1.5rem;
  line-height: 1.5;
  width: 100%;
  min-height: 100vh;
  background-size: 0;
  padding-bottom: 30px;

  @media $small-mobile {
    padding-top: 68vw;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    background: $white;
    position: absolute;
    left: 0; top: 0;
    z-index: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 100%; height: 100%;
    background-image: inherit;
    background-position: 50% -80px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    left: 0; top: 0;
    z-index: 0;

    @media $small-mobile {
      background-position: 50% 0;
    }
  }

  /* @media $notebook {
    width: calc(100% - 80px);
    box-sizing: border-box;
  } */

  .popup__layout {
    position: relative;
    z-index: 1;

    @media $not-notebook {
      width: calc(72vw - 16px);
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }

  .popup__layout--double {
    display: flex;
    position: relative;
    margin-top: 80px;

    &:before {
      content: '';
      display: block;
      width: 100%; height: 100%;
      position: absolute;
      left: -40px; top: -30px;
      z-index: -1;
      padding: 30px 40px 55px;
      background: $white;
    }

    @media $small-mobile {
      flex-direction: column;
      margin-top: 15px;

      &:before {
        display: none;
      }
    }
  }

  .popup__column:first-child {
    flex: 1.1;
    padding-top: 20px;
  }

  .popup__column:last-child {
    flex: 1;
    margin-left: 90px;

    @media $small-mobile {
      margin-left: 0;
      padding-top: 0;
      margin-top: 35px;
    }
  }

  .popup__footer {
    padding-top: 35px;
  }

  .popup__caption {
    @extend .h2;
    color: $black;
    margin-bottom: .5em;

    @media $not-small-mobile {
      max-width: 50%;
    }

    @media $small-mobile {
      br {
        display: none;
      }
    }
  }

  .popup__subcaption {
    @extend .big-text;
    color: $dark-gray;
    margin-bottom: .5em;
  }

  .popup__buy-btn {
    @extend .btn;
    width: 157px;
    line-height: 54px;
    padding-top: 4px
    background: $white;
    color: $dark-gray;
    font-size: 2rem;
    font-family: $master-font;
    font-weight: $light;
    text-transform: uppercase;
    letter-spacing: .125em;
    margin-left: 32px;

    @media $small-mobile {
      line-height: 46px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &#touch-details {
    .popup__buy-btn {
      border: 3px solid $pink;
      &:hover {
        background-color: $pink;
        color: $white;
      }
    }
  }
  &#derm-details {
    .popup__buy-btn {
      border: 3px solid $navi;
      &:hover {
        background-color: $navi;
        color: $white;
      }
    }
  }
  &#ultra_deep-details {
    .popup__buy-btn {
      border: 3px solid $pale-pink;
      &:hover {
        background-color: $pale-pink;
        color: $white;
      }
    }
  }

  .popup__notice {
    color: $dark-gray;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    margin-bottom: 2em;
    max-width: 330px;
  }

  .incut {
    border: 8px solid #ededeb;
    padding: 30px 40px;
    margin-left: -48px;

    @media $small-mobile {
      margin-left: -10px;
      margin-right: -10px;
      padding: 10px;
    }

    p + p {
      margin-top: 1.5em;
    }
  }

  * + .incut {
    margin-top: 3.5em;
  }

  .small-text {
    display: block;
  }
}

.order-popup {
  overflow: hidden;

  @media $small-mobile {
    width: 100%;
    box-sizing: border-box;
  }

  .popup__close {
    padding: 0;
    width: 40px; height: 40px;
    position: absolute;
    right: 0; top: 0;
    transform: rotate(-45deg);
    background: none;

    &:before, &:after {
      content: '';
      display: block;
      background: $gray;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 15px; height: 2px;
    }

    &:after {
      width: 2px; height: 15px;
    }

    &:hover {
      &:before, &:after {
        background: $pink;
      }
    }
  }

  .popup__caption {
    font-family: $caption-font;
    font-size: 2.2rem;
    font-weight: $medium;
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  .order-form {
    .form__submit {
      padding-left: 0;
    }

    .submit-button {
      margin-left: 0;
      background: $navi;
      color: $white;

      &:hover {
        background: $black;
      }

      &:after {
        background-image: url(/i/arrow-right-white.svg);
      }
    }
  }
}
