@font-face {
  font-family: 'rouble';
  src: url('/i/fonts/rouble-webfont.woff2') format('woff2'),
       url('/i/fonts/rouble-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Minion Pro';
  src: url('/i/fonts/MinionPro-Regular.eot');
  src: local('Minion Pro Regular'), local('MinionPro-Regular'),
       url('/i/fonts/MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/MinionPro-Regular.woff') format('woff'),
       url('/i/fonts/MinionPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: url('/i/fonts/MinionPro-Medium.eot');
  src: local('Minion Pro Medium'), local('MinionPro-Medium'),
       url('/i/fonts/MinionPro-Medium.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/MinionPro-Medium.woff') format('woff'),
       url('/i/fonts/MinionPro-Medium.ttf') format('truetype');
  font-weight: $medium;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/i/fonts/HelveticaNeueCyr-Roman.eot');
  src: local('HelveticaNeueCyr-Roman'),
     url('/i/fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
     url('/i/fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
     url('/i/fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/i/fonts/HelveticaNeueCyr-Medium.eot');
  src: local('HelveticaNeueCyr-Medium'),
       url('/i/fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/HelveticaNeueCyr-Medium.woff') format('woff'),
       url('/i/fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: $medium;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/i/fonts/HelveticaNeueCyr-Light.eot');
  src: local('HelveticaNeueCyr-Light'),
       url('/i/fonts/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/HelveticaNeueCyr-Light.woff') format('woff'),
       url('/i/fonts/HelveticaNeueCyr-Light.ttf') format('truetype');
  font-weight: $light;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/i/fonts/HelveticaNeueCyr-UltraLight.eot');
  src: local('HelveticaNeueCyr-UltraLight'),
       url('/i/fonts/HelveticaNeueCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/HelveticaNeueCyr-UltraLight.woff') format('woff'),
       url('/i/fonts/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
  font-weight: $ultra-light;
  font-style: normal;
}

