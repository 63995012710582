.intro-section {
  background: url(/i/intro-bg.jpg) no-repeat 50% 0 / cover;
  height: 100vh;

  @media $small-handheld {
    background-position: 40% 50%;
  }

  @media $small-handheld {
    background-position: 41% 50%;
  }

  .wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.brand-square {
  box-sizing: border-box;
  width: 548px; min-height: 530px;
  border: 8px solid rgba(223, 172, 187, .4);
  padding-left: 5vw;
  flex-shrink: 0;
  max-width: 100%;

  @media $handheld {
    width: 460px; min-height: 450px;
  }

  @media $small-handheld {
    border: none;
  }

  @media $small-mobile {
    width: auto;
    border: none;
    padding-left: 0;
    padding-right: 0;
    min-height: auto;
  }
}

.brand-circle {
  box-sizing: border-box;
  width: 552px; min-height: 577px;
  padding-left: 180px;
  flex-shrink: 0;
  max-width: 100%;
  background: url(/i/brand-circle.svg) no-repeat 0 50% / contain;
  margin-left: -70px;

  @media $notebook-and-less {
    padding-left: 160px;
  }

  @media $handheld {
    width: 460px; min-height: 470px;
    margin-left: -40px;
    padding-left: 130px;
  }

  @media $small-handheld {
    border: none;
  }

  @media $small-mobile {
    width: auto;
    border: none;
    padding-left: 0;
    padding-right: 0;
    min-height: auto;
    background: none;
    margin-left: 0;
  }
}

.intro-section__caption-block {
  display: flex;
  align-items: center;

  @media $not-notebook {
    margin-top: -6vh;
  }

  @media $notebook-and-less {
    margin-top: 70px;
  }

  @media $small-handheld {
    background: none;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    min-height: auto;
  }
}

.intro-section__caption {
  @extend .h1;
  font-weight: normal;

  +media-lt(1800px) {
    font-size: 8rem;
  }

  +media-lt(1680px) {
    font-size: 7rem;
  }

  +media-lt(1540px) {
    font-size: 6rem;
  }

  +media-lt(1200px) {
    font-size: 5rem;
  }

  @media $small-handheld {
    font-size: 2.2rem;
    max-width: 50%;
  }
}

.intro-section__subcaption {
  font-size: 3rem;
  font-family: $caption-font;
  margin-top: .75em;

  @media $small-handheld {
    font-size: 1.4rem;
    line-height: 1.2;
  }
}


.about-brand-section {
  background: url(/i/about-brand-bg.jpg) no-repeat 0 50% / cover;
  padding-top: 135px;
  padding-bottom: 145px;
  overflow: hidden;

  .wrapper {
    position: relative;
  }

  @media $handheld {
    background-position: 35% 50%;
  }

  @media $small-handheld {
    @media $not-small-mobile {
      background-position: 45% 50%;
    }
  }

  @media $small-mobile {
    padding-top: 70px;
    padding-bottom: 0;
  }
}

.about-brand-section__caption-block {
  display: flex;
  align-items: center;
  position: relative;

  @media $small-mobile {
    margin-bottom: 30px;
  }
}

.about-brand-section__caption-text {
  @media $not-small-mobile {
    margin-right: -90%;

    @media $small-handheld {
      margin-right: -50%;
    }
  }
}

.about-brand-section__products-link {
  position: absolute;
  left: 0; top: calc(100% - 115px);
  width: 340px; height: 340px;
  background: url(/i/products-pre.jpg) no-repeat 50%;
  border-radius: 50%;
  text-decoration: none;

  &:hover {
    color: $black;
  }

  span {
    display: block;
    position: absolute;
    left: 50%; top: calc(100% + 1em);
    transform: translateX(-50%);
    border-bottom: 1px solid $white;
    line-height: 1.2;
  }

  @media $small-handheld {
    display: none;
  }
}

.about-brand-section__certificates {
  position: absolute;
  top: 35%; right: 0;
  transform: translateY(-50%);

  +media-lt(1470px) {
    img {
      max-width: 150px;
    }
  }

  +media-lt(1350px) {
    img {
      max-width: 100px;
    }
  }

  +media-lt(1230px) {
    top: 50%;
  }

  +media-lt(1120px) {
    top: 45%;
  }

  @media $small-handheld {
    position: relative;
    top: 0;
    transform: none;

    img {
      max-width: calc(50% - 10px);
    }
  }

  a {
    text-decoration: none;
  }

  a + a {
    margin-left: 20px;

    +media-lt(1350px) {
      margin-left: 10px;
    }
  }
}

.about-brand-section__history {
  white-space: nowrap;
  overflow: hidden;

  .item {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    white-space: normal;
    padding-right: 3vw;

    a {
      color: $black;
    }
  }

  .item__year {
    @extend .h3;
    margin-bottom: .2em;
  }
}

.about-brand-section__history-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 45px;
  margin-right: -8vw;
  margin-left: 25vw;
  background: rgba($white, .5);
  padding: 50px 3vw;

  @media $handheld {
    margin-right: 0;
  }

  @media $small-handheld {
    margin-left: 0;
    margin-top: 50px;
  }

  @media $small-mobile {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  // пытаемся предотвратить баг на macbook air
  .slick-slider {
    max-width: calc(100% - 72px - 80px);
    @media $small-mobile {
      max-width: calc(100vw - 40px);
    }
  }

  .slick-arrow {
    font-size: 0;
    flex-shrink: 0;
    max-width: width('i/arrow-left.svg');
    transition: all .25s ease;

    @media $small-mobile {
      transform: none;
    }
  }

  .slick-prev {
    width: width('i/arrow-left.svg'); height: 30px;
    background: url(/i/arrow-left.svg) no-repeat 0 50%;
    margin-right: 2vw;

    @media $small-mobile {
      width: 80px;
      position: absolute;
      right: calc(50% + 10px); bottom: 10px;
    }
  }

  .slick-next {
    width: width('i/arrow-right.svg'); height: 30px;
    background: url(/i/arrow-right.svg) no-repeat 100% 50%;
    margin-left: 2vw;

    @media $small-mobile {
      width: 80px;
      position: absolute;
      left: calc(50% + 10px); bottom: 10px;
    }
  }

  .slick-disabled {
    max-width: 0;
    opacity: 0;
  }
}


.manufactoring-section__screen {
  padding-top: 100px;
  padding-bottom: 150px;
  min-height: 1024px;
  box-sizing: border-box;
}

.manufactoring-section__screen--1 {
  background: url('/i/manufactoring-bg-1.jpg') no-repeat 50% / cover;

  @media $small-mobile {
    background-size: 100% auto;
    background-position: 50% 100%;
    padding-bottom: 50vw;
  }
}

.manufactoring-section__screen--2 {
  background: url('/i/manufactoring-bg-2.jpg') no-repeat 50% / cover;

  @media $small-mobile {
    background-size: 100% auto;
    background-position: 50% 100%;
    padding-bottom: 60vw;
  }
}

.wrapper--compensate {
  @media $not-small-handheld {
    padding-left: 110px;

    @media $notebook-and-less {
      padding-left: 90px;
    }
  }
}

.manufactoring-section__flag {
  width: width('i/israel.svg'); height: height('i/israel.svg');
  background: url(/i/israel.svg) no-repeat 50%;
  margin-top: 5em;
  margin-bottom: 2em;
}

.manufactoring-section__subcaption {
  max-width: 650px;
  margin-bottom: 2em;

  @media $small-mobile {
    margin-bottom: 1em;
  }
}

.manufactoring-section__text {
  max-width: 650px;

  p + p {
    margin-top: 1em;
  }
}

.manufactoring-group {
  .manufactoring-section__subcaption {
    margin-bottom: .5em;
  }
}

.manufactoring-group + .manufactoring-group {
  margin-top: 5em;
}

.manufactoring-group__text {
  padding-top: 50px;
  padding-bottom: 50px;

  p {
    max-width: 300px;
  }

  p + p {
    margin-top: 1.5em;
  }

  @media $small-mobile {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}


.production-section {
  position: relative;
  background: url(/i/production-bg.jpg) no-repeat 0 50% / cover;

  @media $small-handheld {
    /* background-position: 30% -100px;
    background-repeat: repeat-y; */
    background: #f0f0f0;
  }

  > .wrapper {
    padding-top: 125px;
    padding-bottom: 135px;

    @media $small-mobile {
      padding-top: 75px;
      padding-bottom: 60px;
    }
  }

  .caption {
    position: relative;
    z-index: 10;
    margin-bottom: 220px;

    @media $not-small-mobile {
      /* padding-left: calc(5vw + 8px); */
    }

    @media $small-mobile {
      margin-bottom: 2em;
    }
  }
}

.production-section__text {
  max-width: 676px;
}

.products-list {
  display: flex;
  margin-top: 100px;
  font-size: 0;

  @media $not-small-handheld {
    margin-left: -25px;
  }

  @media $small-handheld {
    flex-direction: column;
  }

  @media $small-mobile {
    margin-top: 50px;
  }

  .product {
    font-size: $font-size;
    width: 274px;
    padding: 10px 25px 65px;
    box-sizing: border-box;
    background: $white;
    position: relative;

    @media $small-handheld {
      width: 100%;
    }
  }

  .product + .product {
    margin-left: 28px;

    @media $small-handheld {
      margin-left: 0;
      margin-top: 25px;
    }
  }

  .product--touch {
    border-top: 12px solid $pink;

    a:hover {
      color: $pink;
    }

    .product__buy-btn:hover {
      bordered-btn(2px, $pink);
    }
  }

  .product--ultra_deep {
    border-top: 12px solid $pale-pink;

    a:hover {
      color: $pale-pink;
    }

    .product__buy-btn:hover {
      bordered-btn(2px, $pale-pink);
    }
  }

  .product--derm {
    border-top: 12px solid $navi;

    a:hover {
      color: $navi;
    }

    .product__buy-btn:hover {
      bordered-btn(2px, $navi);
    }
  }

  .product__link {
    text-decoration: none;
    display: block;
  }

  .product__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .product__subcaption {
    display: block;
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: none;
  }

  .product__caption {
    font-family: $caption-font;
    font-size: 2.2rem;
    font-weight: $medium;
    text-transform: uppercase;
  }

  .product__subcaption {
    @extend .big-text;
    margin-bottom: -.5em;
  }

  .product__consistency {
    font-size: 1.8rem;
    margin-bottom: .15em;
  }

  .product__text {
    font-size: 1.3rem;
    font-weight: $light;

    p:last-child:after {
      content: '';
      @extend .btn;
      vertical-align: middle;
      width: width('i/details-arrow.svg'); height: height('i/details-arrow.svg');
      background: url(/i/details-arrow.svg) no-repeat 100% 50%;
      margin-left: 20px;
    }
  }

  .product__buy-btn {
    @extend .btn;
    position: absolute;
    right: 25px; bottom: 18px;
    font-size: 1rem;
    line-height: 2.5;
    padding: 2px 15px 0 15px;
    text-transform: uppercase;
    color: $dark-gray;
    bordered-btn(2px, #dfdedc);
  }

  /* .product__details-link {
    @extend .btn;
    width: width('i/details-arrow.svg'); height: height('i/details-arrow.svg');
    background: url(/i/details-arrow.svg) no-repeat 100% 50%;
    margin-top: 3em;

    @media $handheld {
      margin-top: 1.5em;
    }
  } */
}


.collaboration-section {
  background: url(/i/collaboration-bg.jpg) no-repeat 50% / cover;
  color: #5c5f69;
  padding-top: 125px;
  padding-bottom: 75px;

  a:hover {
    color: #5c5f69;
  }

  @media $not-small-handheld {
    .caption {
      /* padding-left: calc(5vw + 8px); */
    }
  }

  @media $small-mobile {
    padding-top: 75px;
    padding-bottom: 0;
  }
}

.collaboration-section__content {
  display: flex;
  align-items: flex-end;
  font-size: 1.5rem;

  @media $not-small-handheld {
    /* padding-left: calc(5vw + 8px); */
  }

  @media $small-handheld {
    flex-direction: column;
  }

  p + p {
    margin-top: 1.5em;
  }

  img {
    margin-top: 1em;
  }
}

.collaboration-section__text {
  flex-basis: 405px;
  margin-right: 45px;

  @media $small-handheld {
    margin: 0;
    margin-bottom: 30px;
  }

  @media $small-mobile {
    font-size: 1.4rem;
    line-height: 1.2;
    flex-basis: auto;
  }
}

.collaboration-section__form {
  flex: 1;
  max-width: 823px;

  @media $small-mobile {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.collaboration-form {
  background: rgba($white, .6);
  padding: 45px 5vw 80px;

  @media $small-mobile {
    border: none;
    padding: 40px 20px;
  }

  .form__caption {
    @extend .big-text;
  }

  .form__subcaption {
    font-size: 1rem;
    letter-spacing: 0.02em;
    margin-bottom: 35px;
    color: rgba(35, 35, 35, 0.5);
  }
}


.news-section {
  background: url(/i/news-bg.jpg) no-repeat 50% / cover;
  padding-top: 135px;
  padding-bottom: 40px;
  font-size: 1.5rem;
  position: relative;
  z-index: 10;

  .caption {
    @media $not-small-mobile {
      margin-bottom: 0;
    }
  }

  @media $not-small-mobile {
    .caption {
      /* padding-left: calc(5vw + 8px); */
    }
  }

  @media $small-mobile {
    padding-top: 75px;
    padding-bottom: 40px;
  }
}

.main-news {
  display: flex;
  align-items: flex-start;

  @media $small-mobile {
    flex-direction: column;
  }
}

.main-news__image {
  margin-top: 10px;
  line-height: 0;

  @media $small-mobile {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 10px;
    order: 1;
  }
}

.main-news__content {
  display: flex;
  align-items: center;
  padding-right: 60px;

  @media $small-mobile {
    order: 2;
    padding-right: 0;
  }
}

.main-news__date {
  font-size: 1.4rem;
  font-weight: $light;

  @media $small-mobile {
    font-size: 1.2rem;
  }
}

.main-news__caption {
  font-size: 1.8rem;
  font-weight: $medium;
  line-height: 1.65;
  margin-top: .25em;

  a {
    text-decoration: none;
  }

  @media $small-mobile {
    font-size: 1.6rem;
    margin-top: 0;
  }
}

.main-news__text {
  margin-top: 2em;
  max-width: 280px;

  @media $small-mobile {
    margin-top: .5em;
    max-width: 100%;
  }
}

.main-news__more {
  display: block;
  width: width('i/arrow-right.svg'); height: height('i/arrow-right.svg');
  background: url(/i/arrow-right.svg) no-repeat 50%;
  margin-top: 2em;
}

.news-list {
  padding-right: 7vw;
  margin-right: 7vw;
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-right: 4px solid $pink;

  @media $small-mobile {
    flex-direction: column;
    margin-top: 35px;
    border-right: 0;
  }

  .news {
    padding-left: 42px;
    border-left: 4px solid $pink;
    max-width: 326px;

    @media $small-mobile {
      padding-left: 20px;
    }
  }

  .news + .news {
    margin-left: 5vw;

    @media $small-mobile {
      margin-left: 0;
      margin-top: 30px;
    }
  }

  .news__caption {
    font-size: 1.8rem;
    font-weight: $medium;
    line-height: 1.65;

    a {
      text-decoration: none;
    }
  }

  .news__text {
    @media $small-mobile {
      font-size: 1.2rem;
    }
  }

  .news__more {
    display: block;
    width: width('i/arrow-right.svg'); height: height('i/arrow-right.svg');
    background: url(/i/arrow-right.svg) no-repeat 50%;
    margin-top: 1.25em;
  }
}


.contact-section {
  padding-top: 135px;
  padding-bottom: 85px;
  background: url(/i/contact-bg.jpg) no-repeat 50% / cover;

  @media $small-mobile {
    padding-top: 75px;
    padding-bottom: 0;
  }

  @media $not-small-mobile {
    .caption {
      margin-bottom: 0;
      /* padding-left: calc(5vw + 8px); */
    }
  }
}

.contact-content {
  @extend .brand-circle;
  display: flex;
  align-items: center;
  background-image: url(/i/brand-circle-navi.svg);
  padding-left: 180px;
  min-height: 610px;
  background-position-y: top;
  margin-top: 10px;
  padding-top: 20px;

  @media $small-mobile {
    min-height: inherit;
    background: none;
    padding-left: 0;
    margin-left: 0;
  }
}

.contact-phone {
  font-size: 4.6rem;
  font-family: $caption-font;
  font-weight: $medium;

  a {
    color: $black;
  }

  @media $handheld {
    font-size: 3rem;
  }

  @media $small-mobile {
    font-size: 2.6rem;
  }
}

.contact-address {
  @extend .big-text;

  a {
    display: inline-block;
    line-height: 1;
    text-decoration: none;
    border-bottom: 1px solid rgba($gray, .5);
  }
}

.contact-socials {
  float: right;
  margin-top: -90px;
  margin-right: -15px;

  @media only screen and (max-width: 1023px){
    float: left;
    margin-top: -85px;
    margin-left: 90px;
    margin-right: inherit;
  }

  @media $small-mobile {
    margin-top: -10px;
    margin-left: 12px;
    margin-bottom: 20px;
  }

  .icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-size: cover;
    transition: .3s;

    &.icon-fb {
      background-image: url('/i/icon-fb-blue.svg');
      &:hover {
        filter: contrast(175%) brightness(105%);
      }
    }
    &.icon-inst {
      background-image: url('/i/icon-inst-blue.svg');
      &:hover {
        filter: contrast(175%) brightness(105%);
      }
    }
  }
}

.contact-map {
  margin-top: -160px;
  margin-left: 110px;
  line-height: 0;

  @media $handheld {
    margin-top: -120px;
  }

  @media $small-mobile {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
