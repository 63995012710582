.header {
  background: $white;
  width: 100%;
  position: fixed;
  top: 0; left: 0;
  z-index: 100;
  box-sizing: border-box;

  .wrapper {
    height: $header-height;
    line-height: @height + 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media $small-mobile {
      height: $mobile-header-height;
    }
  }
}

.header__burger {
  @media $not-small-mobile {
    display: none !important;
  }

  @extend .btn;
  position: absolute;
  left: 0; top: 0;
  width: $mobile-header-height; height: @width;

  .bars {
    pointer-events: none;

    &, &:before, &:after {
      display: block;
      width: 16px; height: 2px;
      background: $gray;
      position: absolute;
      left: 50%
      transition: all .25s ease;
    }

    & {
      top: 50%;
      transform: translate(-50%, -50%);

      .menu-is-opened & {
        background-color: transparent;
      }
    }

    &:before, &:after {
      content: '';
      transform: translateX(-50%);
    }

    &:before {
      bottom: 7px;

      .menu-is-opened & {
        bottom: 0;
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    &:after {
      top: 7px;

      .menu-is-opened & {
        top: 0;
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }
}

.header__logo {
  display: block;
  text-decoration: none;
  width: width('i/renaissance-logo.svg'); height: 100%;
  background: url(/i/renaissance-logo.svg) no-repeat 50%;
  font-size: 0;

  @media $small-mobile {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.header__menu {
  @extend .menu, .horizontal-menu;
  margin-left: auto;
  padding-right: 3vw;
  font-size: 1.5rem;
  letter-spacing: 0.02em;


  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  li + li {
    margin-left: 2vw;
  }

  @media $small-handheld {
    font-size: 1.1rem;
    padding-left: 1em;
    padding-right: 1em;
  }

  @media $small-mobile {
    display: none;
    position: fixed;
    left: 0; top: $mobile-header-height;
    z-index: 100;
    background: $white;
    padding: 20px;
    width: 100%;
    line-height: 2;
    box-shadow: 0 10px 10px rgba($black, .15);

    ul {
      display: inline-block;
    }

    li {
      display: block;
      float: none;
    }

    li + li {
      margin-left: 0;
      margin-top: .25em;
    }

    .menu-is-opened & {
      display: block;
    }
  }

  a {
    color: $white;
    position: relative;
  }

  a:before,
  a:after {
    content: attr(data-name);
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    color: $link-color;
    white-space: nowrap;
  }

  a:after {
    font-weight: bold;
  }

  a:hover {
    &:before, &:after {
      color: $hovered-link-color;
    }
  }

  li.is-active a:before {
    display: none;
  }

  li:not(.is-active) a:after {
    display: none;
  }

  .error-page & {
    display: none;
  }
}


.header__socials {
  height: 48px;
  margin-left: -15px;
  display: flex;

  .icon {
    display: inline-block;
    width: 48px; height: 48px;
    background-size: cover;

    &.icon-fb {
      background-image: url('/i/icon-fb-gray.svg');
      &:hover { background-image: url('/i/icon-fb-pink.svg'); }
    }
    &.icon-inst {
      background-image: url('/i/icon-inst-gray.svg');
      &:hover { background-image: url('/i/icon-inst-pink.svg'); }
    }
  }

  @media $small-mobile {
    margin-left: 30px;
    margin-right: -15px;
    margin-top: 0px;
  }
  @media only screen and (max-width: 374px){
    margin-right: -25px;
  }
}