// Floats

.fl {
  float: left;
}

.fr {
  float: right;
}

.no-fl {
  float: none;
}

@media $not-small-mobile {
  .l-main {
    float: left;
    width: 49%;
  }

  .r-main {
    float: right;
    width: 49%;
  }
}

@media $small-mobile {
  .l-main + .r-main {
    margin-top: 10px;
  }
}


// Containers

.group, .nofloat {
  clearfix();
}

.no-overflow {
  overflow: hidden;
}

.flex-group {
  display: flex;

  &.justify {
    justify-content: space-between;
  }
}

.wrapper {
  box-sizing: border-box;
  margin-left: 8vw;
  margin-right: 8vw;

  @media $tablet {
    margin-left: 4vw;
    margin-right: 4vw;
  }

  @media $mobile {
    margin-left: 20px;
    margin-right: 20px;
  }
}


// Margins

.m3 {
  margin-bottom: 3px;
}

.m7 {
  margin-bottom: 7px;
}

for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}


// Position

.relative {
  position: relative;
}

.static {
  position: static !important;
}


// Display

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}


// Devices display

@media $handheld {
  .only-desktop {
    display: none !important;
  }
}

@media $not-mobile {
  .only-mobile {
    display: none !important;
  }
}

@media $not-small-mobile {
  .only-small-mobile {
    display: none !important;
  }
}

@media $not-small-handheld {
  .only-small-handheld { // mobile + portrait-tablet
    display: none !important;
  }
}

@media $desktop {
  .not-desktop { // mobile + tablet
    display: none !important;
  }
}

@media $mobile {
  .not-mobile { // desktop + tablet
    display: none !important;
  }
}

@media $small-mobile {
  .not-small-mobile { // desktop + tablet
    display: none !important;
  }
}

@media $small-handheld {
  .not-small-handheld { // desktop + landscape tablet
    display: none !important;
  }
}
