html {
  font: $root-font-size / $line-height $master-font;

  @media $mobile {
    font-size: $root-mobile-font-size;
  }
}

body {
  background: $body-bg;
  color: $text-color;
  font-size: $font-size;
}

::-moz-selection {
  background: $black;
  color: $white;
}

::selection {
  background: $black;
  color: $white;
}


// Headings

.h1, .h2, .h3, .h4 {
  font-family: $caption-font;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: 0.03em;
}

.h1 {
  @media $not-notebook {
    font-size: 9rem;
    font-weight: $medium;
  }

  @media $notebook {
    font-size: 6.4rem;
  }

  @media $tablet {
    font-size: 5.6rem;
  }

  @media $mobile {
    font-size: 3rem;
  }
}

.h2 {
  @media $not-notebook {
    font-size: 6.4rem;
  }

  @media $notebook {
    font-size: 5.2rem;
  }

  @media $tablet {
    font-size: 4.6rem;
  }

  @media $mobile {
    font-size: 2rem;
  }
}

.h3 {
  font-size: 3.6rem;

  @media $tablet {
    font-size: 2rem;
  }

  @media $mobile {
    font-size: 1.6rem;
  }
}

.h4 {
  font-size: 1rem;
}

.caption {
  @extend .h2
  margin-bottom: .5em;

  a {
    text-decoration: none;
  }
}

.caption-small {
  font-family: $caption-font;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: 0.03em;

  @media $not-notebook {
    font-size: 4.2rem;
  }
  @media $notebook {
    font-size: 3.8rem;
  }
  @media $tablet {
    font-size: 2.8rem;
  }
  @media $mobile {
    font-size: 2rem;
  }
  margin-bottom: .5em;
}

.big-text {
  font-family: $caption-font;
  font-size: 3rem;
  line-height: 1.35;

  @media $notebook-and-less {
    font-size: 2.6rem;
  }

  @media $small-mobile {
    font-size: 2rem;
  }
}

.small-text {
  font-size: 1.2rem;
}


// Sub, sup

sub, sup {
  position: relative;
  font-size: .6em;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


// Links

a {
  color: $link-color;
  transition: color .25s ease;

  &:hover {
    color: $hovered-link-color;
  }
}

a.hova, .hova a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.unhova, .unhova a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

a.nova, .nova a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

a[href^="tel:"] {
  text-decoration: none;

  .is-desktop & {
    cursor: text;
    pointer-events: none;
  }
}


// Sizes

for $i in (10..40) {
  .s{$i} {
    font-size: remove-unit(1 / $root-font-size) * $i * 1rem;
    line-height: $line-height;
  }
}


// Font weight

.thin, .tt {
  font-weight: $thin;
}

.light, .ll {
  font-weight: $light;
}

.normal, .nn {
  font-weight: normal;
}

.bold, .bb {
  font-weight: bold;
}

.medium, .mm {
  font-weight: $medium;
}


// Font style

.style-normal {
  font-style: normal;
}

.italic, .ii {
  font-style: italic;
}


// Text case

.uppercase, .upcase {
  text-transform: uppercase;
}

.nocase {
  text-transform: none !important;
}


// Text decoration

.underline {
  text-decoration: underline;
}


// Colors

.black, a.black {
  color: $black;
}

.white, a.white {
  color: $white;
}

.gray, a.gray {
  color: $dark-gray;
}


// Text align

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}


// Word wrap

.nowrap {
  white-space: nowrap;
}


// Text container

.text-container {
  clearfix();

  * + *:not(.no-margin) {
    margin-top: 2em;

    @media $small-mobile {
      margin-top: 1em;
    }
  }

  ol {
    margin-left: 1em;
    list-style-position: outside;
  }

  ul.no-margin li {
    margin: 5px 0 0 0 !important;
  }

  ul > li {
    list-style: none;

    &:before {
      content: '●';
      font-size: .75em;
      display: inline-block;
      position: absolute;
      left: 0; top: .2em;
    }
  }

  li {
    padding-left: 1em;
    position: relative;
  }

  li {
    margin: .25em 0;

    > ul, ol {
      margin-left: 1em;
    }
  }

  .list-item {
    margin-top: 1em !important;
  }

  .col_a {
    min-width: 200px;
    display: inline-block;
    margin-top: 1em !important;
  }

  .col_b {
    display: inline-block;
    margin-top: 1em !important;
  }
}

.info-section {
  .text-container {
    * + *:not(.no-margin) {
      margin-top: 5px;

      @media $small-mobile {
        margin-top: 0;
      }
    }
  }
}




// HR

hr {
  height: 1px;
  margin: .5em 0;
  background: $black;
}


// Icons

.icon {
  display: inline-block;
}


// Video

.video {
  position: relative;
  padding-bottom: (9 / 21) * 100%; // 21:9 aspect ratio

  > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  &.ar-16by9 {
    padding-bottom: (9 / 16) * 100%; // 16:9 aspect ratio
  }

  &.ar-4by3 {
    padding-bottom: (3 / 4) * 100%; // 4:3 aspect ratio
  }
}


// Galleries

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;

  .item {
    display: block;
    max-width: 320px;
    margin-right: 20px;
    margin-bottom: 20px;
    line-height: 0;
  }

  .item + .item {
    margin-top: 0;
  }
}

.gallery-caption {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: -1.5em;
}
