.footer {
  background: #d8dce2;
  font-size: 1.5rem;

  @media $notebook {
    font-size: 1.3rem;
  }

  @media $handheld {
    font-size: 1.1rem;
  }

  &, a {
    color: #f2f2f2;
  }

  a {
    text-decoration: none;

    &:hover {
      color: $hovered-link-color;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr .9fr 1.3fr 1.3fr 1.3fr;
    grid-column-gap: 30px;
    align-items: center;

     @media $notebook-and-less {
      grid-template-columns: 1fr .9fr 1.4fr 1fr 1.1fr;
    }

    @media $small-mobile {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;

      > * {
        justify-self: center;
        text-align: center;
      }
    }
  }
}

.footer__line--first {
  background: #8c9bcd;
  color: #dbe3ff;
  white-space: nowrap;

  @media $handheld {
    font-size: 1.1rem;
  }

  @media $small-mobile {
    display: none;
  }

  .wrapper {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

.footer__line--second {
  background: $navi;

  .wrapper {
    padding-top: 20px;
    padding-bottom: 25px;

    @media $small-mobile {
      padding-bottom: 50px;
    }
  }
}

.footer__logo {
  display: block;
  width: 178px; height: 39px;
  background: url(/i/renaissance-logo-white.svg) no-repeat 50% / contain;
  font-size: 0;
  text-decoration: none;

  @media $notebook-and-less {
    width: 160px;
  }

  @media $handheld {
    width: 120px;
  }
}

.footer__korman-logo {
  display: block;
  width: width('i/korman-logo.svg'); height: height('i/korman-logo.svg');
  background: url(/i/korman-logo.svg) no-repeat 50% / contain;
  font-size: 0;
  text-decoration: none;
}

.footer__bv-logo {
  display: block;
  width: 178px; height: 60px;
  background: url(/i/bv-logo.svg) no-repeat 50% / contain;
  font-size: 0;
  text-decoration: none;
}

.footer__copyright {
  @media $small-mobile {
    margin-top: 1em;
  }
}

.footer__dev-copy {
  display: block;
  width: width('i/shogo-logo.svg'); height: height('i/shogo-logo.svg');
  background: url(/i/shogo-logo.svg) no-repeat 50%;
}

.footer__link {
  display: inline-block;
  border-bottom: 1px solid #acbbee;
  justify-self: flex-start;
  line-height: 1.1;
  white-space: nowrap;

  &:hover {
    border-bottom-color: $pale-pink;
  }
}


.footer__socials {
  margin-left: -15px;

  @media $small-mobile {
    margin-left: 0px;
    margin-top: 5px;
  }
}
