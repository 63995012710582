.news-page {
  background: url(/i/news-page-bg.jpg) no-repeat 50% 0;
  padding-bottom: 90px;

  @media $small-mobile {
    padding-bottom: 50px;
  }
}

.news-page__caption {
  margin-bottom: 1em;
}

.news-page-list {
  .items {
    display: flex;
    flex-wrap: wrap;
  }

  .news-announce {
    flex-basis: calc(25% - 7.5vw / 4);
    background: $white;
    margin-left: 2.5vw;
    margin-bottom: 2vw;
    position: relative;
    transition: box-shadow .25s ease;

    @media $small-notebook-and-less {
      flex-basis: calc(33.33% - 20px);
      margin-left: 30px;
      margin-bottom: 30px;
    }

    @media $small-handheld {
      flex-basis: calc(50% - 15px);
    }

    @media $small-mobile {
      flex-basis: 100%;
      margin-left: 0;
    }

    &:hover {
      bordered-btn(2px, $pink);
    }
  }

  @media $not-small-notebook {
    .news-announce:nth-child(4n + 1) {
      margin-left: 0;
    }
  }

  @media $small-notebook-and-less {
    .news-announce:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  @media $small-handheld {
    .news-announce:nth-child(2n + 1) {
      margin-left: 0;
    }
  }

  .news-announce__image {
    display: block;
    position: relative;
    z-index: 10;

    &:before {
      content: '';
      display: block;
      padding-bottom: 62%;
    }

    img {
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
      object-fit: cover;
    }
  }

  .news-announce__content {
    padding: 30px 25px 70px;
    border-top: 12px solid $pink;
  }

  .news-announce__date {
    margin-bottom: .5em;
  }

  .news-announce__name {
    margin-bottom: 1.5em;

    a {
      text-decoration: none;
      color: $dark-gray;
      font-size: 2rem;
      font-weight: $medium;

      @media $small-mobile {
        font-size: 1.6rem;
      }
    }
  }

  .news-announce__more-link {
    @extend .btn;
    width: width('i/arrow-right.svg'); height: height('i/arrow-right.svg');
    background: url(/i/arrow-right.svg) no-repeat 50%;
    position: absolute;
    left: 25px; bottom: 30px;

    &:before {
      content: '';
      display: block;
      width: 100%; height: 40px;
      position: absolute;
      left: 0; top: 50%;
      transform: translateY(-50%);
    }
  }
}
