// Base

input,
textarea {
  font: inherit;
}

.form {
  span.required {
    color: $warning-color;
  }
}

.inp,
.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: .33em 35px;
  line-height: 1.25em;
  color: lighten($text-color, 5);
  border: none;
  border-bottom: 1px solid rgba(128, 136, 149, 0.5);
  background: none;
  transition: all .25s ease;
  font-size: 1.5rem;
  letter-spacing: 0.02em;

  &:focus {
    color: #5c5f69;
    border-color: rgba(128, 136, 149, 1);
  }

  .form__field.error > & {
    border-color: $warning-color;
    color: $warning-color;
  }
}

.textarea,
textarea.inp {
  min-height: 2em;
}

.checkbox,
.radio {
  padding-left: 35px;
  position: relative;
  font-size: 1.5rem;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:before {
    content: '';
    display: block;
    width: 20px; height: @width;
    box-sizing: border-box;
    position: absolute;
    left: 0; top: 2px;
    border: 5px solid rgba(#808895, .3);
    border-radius: 50%;
    transition: all .25s ease;
  }

  &.is-checked:before {
    background: #808895;
    border-color: #808895;
  }

  input {
    display: none;
  }
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  padding: 0 32px;
  position: relative;

  .inp {
    vertical-align: middle;
    border: none;
    width: 40px;
    padding: 0;
    text-align: center;
    font-size: 1.8rem;
  }

  .spinner-up,
  .spinner-down {
    @extend .btn;
    box-sizing: border-box;
    vertical-align: middle;
    width: 30px; height: @width;
    line-height: 17px;
    text-align: center;
    border: 2px solid $black;
    border-radius: 50%;
    background: transparent;
    font-size: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .spinner-down {
    left: 0;
  }

  .spinner-up {
    right: 0;
  }

  .spinner-unit {
    position: absolute;
    left: 50%; top: 100%;
    transform: translateX(-50%);
    margin-top: 3px;
    font-size: 1.4rem;
  }
}


.form__row {
  clearfix();

  + .form__row {
    .one-column-form & {
      margin-top: 2.25em;
    }

    .two-columns-form & {
      margin-top: 1.5em;
    }
  }
}

.form__label {
  .one-column-form & {
    margin-bottom: .15em;
  }

  .two-columns-form & {
    float: left;
  }
}

.form__field {
  position: relative;

  .two-columns-form & {
    float: right;
  }

  .errorMessage {
    display: none;
    font-size: 1.2rem;
    line-height: 1.1;
    color: $warning-color;

    @media $not-small-mobile {
      margin-top: 10px;
    }
  }

  &.error {
    .errorMessage {
      display: block;
    }
  }
}

.form__hint {
  font-size: 1rem;
  color: rgba(35, 35, 35, 0.5);

  .two-columns-form & {
    margin-top: 1.25em;
  }
}

.form__submit {
  .one-column-form & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
    padding-left: 35px;

    @media $small-mobile {
      flex-direction: column;
      padding-left: 0;

      a {
        white-space: nowrap;
      }
    }
  }

  .two-columns-form & {
    margin-top: 1.25em;
  }

  .submit-button {
    @extend .btn;
    line-height: 66px;
    background: $white;
    color: $navi;
    padding: 0 27px 0 30px;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    white-space: nowrap;
    margin-left: 35px;

    @media $small-mobile {
      margin-left: 0;
      margin-top: 20px;
      padding: 0 20px;
      width: 100%;
    }

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 25px;
      width: width('i/arrow-right-navi.svg'); height: height('i/arrow-right-navi.svg');
      background: url(/i/arrow-right-navi.svg) no-repeat 50%;

      @media $small-mobile {
        display: none;
      }
    }

    &:hover {
      background: darken($navi, 10);
      color: $white;

      &:after {
        background-image: url(/i/arrow-right-white.svg);
      }
    }
  }
}

.form__field--radio {
  .item + .item {
    margin-top: 1em;
  }
}
