// Base

.site-container {
  min-height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
}


// Lift

.go-top-btn.is-visible {
  opacity: 1;
  max-height: 48px;
}

.go-top-btn {
  width: 48px; height: 48px;
  position: fixed;
  right: 15px; bottom: 15px;
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  cursor: pointer;
  background: rgba($black, .5);

  &:hover {
    background: rgba($black, .8);
  }

  &:before {
    content: '';
    display: block;
    width: 18px; height: 18px;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    position: absolute;
    left: 50%; top: 50%;
    transform: rotate(-45deg) translate(-50%,-50%);
    transform-origin: center top;
    margin-left: -3px;
  }
}
