.info-section {
  padding-top: 125px;
  padding-bottom: 75px;

  @media $small-mobile {
    padding-top: 75px;
    padding-bottom: 50px;
  }


}
