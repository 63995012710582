.filter--price-slider {
  height: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 30px 15px;

  .ui-slider-range {
    background: $black;
  }

  .ui-slider-handle {
    width: 25px; height: @width;
    background: #ccc;
    top: -10px;
    margin-left: -12px;
    outline: none;
    box-shadow: 0 5px 8px rgba($black, .25);
  }
}
